import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";
import { useEffect, useRef, useState } from "react";
import utilStyles from "../components/css/utils.module.css";
import { ReactComponent as IconCamera } from "../svg/IconCamera.svg";
import { ReactComponent as IconDownload } from "../svg/IconDownload.svg";
import { ReactComponent as IconTimes } from "../svg/IconTimes.svg";
import { ReactComponent as LoadingSpinner } from "../svg/LoadingSpinner.svg";
import styles from "./css/LogHistory.module.css";
import EntityAbsence from "./EntityAbsence";
import Icon from "./Icon";
import MenuButton from "./MenuButton";
import { normalizeVideoLink } from "./MissionLogProvider";

function VideoPanel({ isShow, videos, chosenVideo, chooseVideo, closePanel }) {
    if (isShow) {
        return (
            <div className={styles["video-panel"]}>
                <div className={styles["grid"]}>
                    {videos.length > 0 &&
                        videos.map((video, index) => (
                            <button
                                type="button"
                                key={index}
                                className={styles["video-btn"]}
                                data-active={chosenVideo === index ? "1" : "0"}
                                data-link={video.url}
                                onClick={() => {
                                    chooseVideo(index);
                                    closePanel();
                                }}
                            >
                                {video.name}
                            </button>
                        ))}
                </div>

                <div className={styles["close"]}>
                    <MenuButton
                        icon={faChevronLeft}
                        iconType="fa"
                        size="2x"
                        action={closePanel}
                    ></MenuButton>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
}

function LogHistoryReplay({ isShow, videos = [], hasIncompleteVideos }) {
    const [loading, setLoading] = useState(false);
    const [chosenVideo, setChosenVideo] = useState(false);
    const [videoIndex, setVideoIndex] = useState(0);
    const [videoLink, setVideoLink] = useState("");
    const [inspectionIndex, setInspectionLink] = useState("");
    const videoRef = useRef();
    useEffect(() => {
        if (videos.length > 0) {
            let videoEl = videoRef.current;
            // setVideoLink(normalizeVideoLink(videos[videoIndex].url));
            setVideoLink(`https://mountpavilla.r2c2.ai/log/videos/${videos[videoIndex].url}`);
            if (videoEl != null) {
                videoEl.load();
            }
        } else {
            setVideoLink("");
        }
    }, [videoIndex, videos]);

    if (!isShow) return <></>;

    if (videos.length === 0) {
        return <EntityAbsence title="No videos"></EntityAbsence>;
    }
    if (hasIncompleteVideos) {
        return (
            <EntityAbsence
                icon={LoadingSpinner}
                iconClass={utilStyles.spin}
                title="Waiting for videos to complete..."
            ></EntityAbsence>
        );
    }
    // there's 1 or more videos
    return (
        <div className={styles["video-container"]}>
            <div className={styles["video-overlay"]}>
                <div className={styles["video-controls"]}>
                    <MenuButton
                        type="svg"
                        icon={IconCamera}
                        divClass={styles["video-chooser-btn"]}
                        action={() => {
                            setChosenVideo((prev) => !prev);
                        }}
                    />
                    <a
                        className={`${styles["video-chooser-btn"]} misc-btn`}
                        href={videoLink}
                        target="_blank"
                        rel="noreferrer"
                        download
                    >
                        <Icon icon={IconDownload} type="svg" width="24px" height="24px" />
                    </a>
                </div>
                <div className={styles["box"]}>{videos[videoIndex].name}</div>
            </div>

            {loading && <div className={styles["loading"]}></div>}

            <VideoPanel
                isShow={chosenVideo}
                videos={videos}
                chosenVideo={videoIndex}
                chooseVideo={(index) => {
                    new Promise((resolve) => {
                        setVideoIndex(index);
                        resolve();
                    }).then(() => {
                        setVideoLink(normalizeVideoLink(videos[videoIndex].url));
                        videoRef.current?.load();
                    });
                }}
                closePanel={() => {
                    setChosenVideo(false);
                }}
            />
            {videos[videoIndex].failed ? (
                <div className={styles["corrupted-video-notice"]}>
                    <EntityAbsence
                        icon={IconTimes}
                        title={`Footage of ${videos[videoIndex].name} camera is corrupted. You can download it for further inspection.`}
                    ></EntityAbsence>
                </div>
            ) : (
                <video
                    className={styles.video}
                    // autoplay
                    onLoadedData={(e) => {
                        e.target.play();
                    }}
                    id={"log-vid"}
                    ref={videoRef}
                    controls
                >
                    <source src={videoLink} type="video/mp4" />
                </video>
            )}
        </div>
    );
}

export default LogHistoryReplay;
