import { useState } from "react";
import styles from "./css/ActionTab.module.css";

import {
    CUSTOM_ACTION_DETECT_ART_DMG,
    CUSTOM_ACTION_PTZ_INSPECTION,
    CUSTOM_ACTION_TEST_CMD,
} from "../lib/protocols/control";
import { useInspection } from "./InspectionProvider";
import SelectArtPiece from "./SelectArtPiece";
import { useSnack } from "./SnackProvider";

import PTZControlPanel from "./PTZControlPanel";
import { usePTZControl } from "./PTZControlProvider";

function ActionInspection({ isShow = true }) {
    const { addToast } = useSnack();
    const { addDetectDmg, testCmd, addPTZInspect } = useInspection();
    const { inPTZControl, setInPTZControl } = usePTZControl();

    const [selectedAction, setSelectedAction] = useState(null);
    const [showSelectArt, setShowSelectArt] = useState(null);
    const [artId, setArtId] = useState(null);

    const actions = {
        [CUSTOM_ACTION_DETECT_ART_DMG]: {
            label: "Detect Art Damage",
            onSelect() {
                setShowSelectArt(CUSTOM_ACTION_DETECT_ART_DMG);
            },
        },
        // [CUSTOM_ACTION_PTZ_INSPECTION]: {
        //     label: "PTZ Inspect",
        //     onSelect() {
        //         setSelectedAction(CUSTOM_ACTION_PTZ_INSPECTION);
        //         addToast({
        //             type: "info",
        //             message: `Adding PTZ inspection to mission...`,
        //             duration: 2000,
        //         });
        //         setInPTZControl(true);
        //     },
        // },
    };

    if (process.env.NODE_ENV === "development") {
        actions[CUSTOM_ACTION_TEST_CMD] = {
            label: "Test Action (visible in dev only)",
            onSelect() {
                setSelectedAction(CUSTOM_ACTION_TEST_CMD);
                testCmd();
            },
        };
    }

    if (!isShow) return <></>;
    return (
        <>
            <div className={styles.grid}>
                {Object.entries(actions).map(([value, { label, onSelect = () => {} }]) => (
                    <button
                        key={value}
                        className={`${styles.btn} ${selectedAction === value ? styles.active : ""}`}
                        onClick={onSelect}
                    >
                        {label}
                    </button>
                ))}
            </div>

            {showSelectArt !== null && (
                <SelectArtPiece
                    artId={artId}
                    onClose={(artId) => {
                        if (artId !== null) {
                            setSelectedAction(CUSTOM_ACTION_DETECT_ART_DMG);
                            setArtId(artId);
                            addDetectDmg(artId);
                        }
                        setShowSelectArt(null);
                    }}
                />
            )}

            {inPTZControl && (
                <PTZControlPanel
                    addToast={addToast}
                    setSelectedAction={setSelectedAction}
                    addPTZInspect={addPTZInspect}
                />
            )}
        </>
    );
}

export default ActionInspection;
