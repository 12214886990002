import { useContext, useState } from "react";
import { useArmControl } from "./ArmControlProvider";
import { CameraContext } from "./CameraProvider";
import { useMission } from "./MissionProvider";

import styles from "./css/Streamer.module.css";

import { useEffect } from "react";
import { CAM_VIEW_AUX, CAM_VIEW_MAIN } from "./CameraProvider";

import { inRange } from "lodash";
import { ARM_AUTO_GRIP } from "../lib/protocols/control";
import { STATE_CUSTOM_ACTION_STARTED } from "../lib/state";
import { getContainedSize } from "../lib/utils";
import ActionBanner from "./ActionBanner";
import ArmPoseView from "./ArmPoseView";
import { useArmState } from "./ArmStateProvider";
import Crosshair from "./Crosshair";
import CustomActionStatus from "./CustomActionStatus";
import { Graph } from "./Graph";
import NotificationArea from "./NotificationArea";
import ProximityAlert from "./ProximityAlert";
import { useSnack } from "./SnackProvider";
import StreamStatus from "./StreamStatus";
import { RobotStateContext } from "./RobotStateProvider";

import DialogBox from "./DialogBox";

import {
    STATE_RECORD_STARTED,
    STATE_RECORD_STARTING,
    STATE_RECORD_STATE_UPDATED,
} from "../lib/state";

// PTZ
import { usePTZControl } from "./PTZControlProvider";
import PTZOverlays from "./PTZOverlays";
import { usePTZState } from "./PTZStateProvider";
import { useActionState } from "./ActionStateProvider";
import { useStreamControl } from "./StreamControlProvider";

// the fixed source of aux video display. currently SPOT arm
const AUX_SOURCE = "arm";
const ArmOverlays = ({ armIsMain, subUrl, setArmIsMain }) => {
    const { armAction, setArmAction, crosshair, isAutoGripMode } = useArmControl();
    return (
        <>
            {armIsMain && !isAutoGripMode && (
                <Crosshair isFocus={crosshair?.isFocus} type={crosshair?.type} />
            )}
            <div className={styles["in-screen-btn-panel"]}>{/*<ArmControlButtonPanel />*/}</div>
            <div className={styles["arm-control-panel"]}>
                {armAction && (
                    <ActionBanner {...armAction} onCancel={() => setArmAction(null)}></ActionBanner>
                )}
            </div>

            <div
                className={styles["sub-screen"]}
                onClick={(e) => {
                    setArmIsMain(!armIsMain);
                    e.stopPropagation();
                }}
            >
                {!armIsMain && <Crosshair isFocus={crosshair?.isFocus} type={crosshair?.type} />}
                <img
                    alt="Camera stream"
                    src={subUrl || `/${process.env.REACT_APP_STREAM_DUMMY}`}
                ></img>
            </div>
            <div className={styles["streamer-left"]}>
                <ArmPoseView className={styles.box} />
            </div>
        </>
    );
};

const Streamer = () => {
    const { proximities } = useContext(RobotStateContext);
    const [isStatusShow, setStatusShow] = useState(false);
    const [timeoutId, setTimeoutId] = useState(null);
    const { views, camConnected, streamStatus, selectSource, getUrlByView } =
        useContext(CameraContext);
    const { setSnack } = useSnack();

    const { missionId, recordEnded, missionState, showGraph } = useMission();
    const { inArmControl, armManipMode, pointerPos, setPointerPos, armIsMain, setArmIsMain } =
        useArmControl();
    const { hasArm } = useArmState();

    const { videoIsRecording } = useStreamControl();

    const { disableAction } = useActionState();
    const { inPTZControl } = usePTZControl();
    const { recordState } = useMission();
    const { hasPTZ } = usePTZState();

    const [showStreamDisconnectDialogBox, setShowStreamDisconnectDialogBox] = useState(true);

    const isRecording = [
        STATE_RECORD_STARTED,
        STATE_RECORD_STARTING,
        STATE_RECORD_STATE_UPDATED,
    ].includes(recordState);

    const getPointerPos = (e) => {
        if (armManipMode === ARM_AUTO_GRIP) {
            const x = e.nativeEvent.offsetX;
            const y = e.nativeEvent.offsetY;
            // TODO: use `useRef` for streamRef instead of getElementById
            const streamRef = document.getElementById("streamRef");
            const streamHeight = streamRef.height;
            const streamWidth = streamRef.width;
            const actualSize = getContainedSize(streamWidth, streamHeight);
            const letterBoxingX = (streamWidth - actualSize.width) / 2;
            const positionX = (x - letterBoxingX) / actualSize.width;
            const positionY = y / actualSize.height;
            if (!inRange(positionX, 0, 1) || !inRange(positionY, 0, 1)) {
                setSnack({ type: "error", message: "Please Click inside the stream" });
                return;
            }
            setPointerPos({ x, y, positionX, positionY });
        } else setPointerPos(false);
    };
    useEffect(() => {
        if (armManipMode !== ARM_AUTO_GRIP) setPointerPos(false);
    }, [armManipMode, setPointerPos]);
    useEffect(() => {
        if (!hasArm || !inArmControl) {
            selectSource(CAM_VIEW_AUX, null);
        } else if (!views[CAM_VIEW_AUX]) {
            selectSource(CAM_VIEW_AUX, AUX_SOURCE);
        }
    }, [hasArm, inArmControl, views, selectSource]);

    const setTempStatusShow = (timeout, show = true) => {
        const resetTimeout = () => {
            clearTimeout(timeoutId);
            setTimeoutId(null);
        };

        if (show) {
            timeoutId && resetTimeout();
            setStatusShow(true);
            setTimeoutId(
                setTimeout(() => {
                    setStatusShow(false);
                    setTimeoutId(null);
                }, timeout)
            );
        } else {
            clearTimeout(timeoutId);
            setStatusShow(false);
            setTimeoutId(null);
        }
    };

    const screenUrl = getUrlByView(armIsMain && inArmControl ? CAM_VIEW_AUX : CAM_VIEW_MAIN);
    const subScreenUrl = getUrlByView(armIsMain && inArmControl ? CAM_VIEW_MAIN : CAM_VIEW_AUX);

    return (
        <div className={styles.container} onClick={(e) => getPointerPos(e)}>
            {armManipMode === ARM_AUTO_GRIP && <Crosshair position={pointerPos} />}
            <ProximityAlert
                {...{
                    camConnected,
                    streamStatus,
                    proximities,
                }}
            />
            {showGraph && missionId && recordEnded && (
                <div className={`${styles["graph-container"]}`}>
                    <Graph translucent={camConnected} id={missionId} missionInfo={missionId} />
                    {missionState === STATE_CUSTOM_ACTION_STARTED && <CustomActionStatus />}
                </div>
            )}

            <div className={styles["record-statuses"]}>
                {!recordEnded && (
                    <div className={styles["record-status"]}>
                        <p>Mission now recording</p>
                    </div>
                )}
                {videoIsRecording && (
                    <div className={styles["record-status"]}>
                        <p>Video now recording</p>
                    </div>
                )}
            </div>
            <div
                className={styles.screen}
                onPointerEnter={() => setTempStatusShow(5000)}
                onMouseLeave={() => setTempStatusShow(0, false)}
            >
                {/* <div className={styles["in-screen-btn-panel"]}>
                    <MainControlButtonPanel />
                </div> */}
                {hasArm && inArmControl && (
                    <ArmOverlays {...{ armIsMain, setArmIsMain, subUrl: subScreenUrl }} />
                )}

                {(!disableAction || isRecording) && hasPTZ && inPTZControl && <PTZOverlays />}

                <div className={styles["stream-status-wrapper"]}>
                    <NotificationArea />
                    <StreamStatus
                        {...{ camConnected, streamStatus, isShow: isStatusShow }}
                    ></StreamStatus>
                </div>

                {showStreamDisconnectDialogBox && !camConnected && (
                    <DialogBox
                        textCancel=""
                        onConfirm={() => {
                            setShowStreamDisconnectDialogBox(false);
                        }}
                    >
                        <p>Streaming is disconnected!</p>
                        <p>Please refresh page for streaming</p>
                    </DialogBox>
                )}

                <img
                    id={"streamRef"}
                    alt="Camera stream"
                    src={screenUrl || `/${process.env.REACT_APP_STREAM_DUMMY}`}
                ></img>
            </div>
        </div>
    );
};

export default Streamer;
